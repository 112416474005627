<script setup lang="ts">
import { computed } from 'vue'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseCollapse from '/~/components/base/collapse/base-collapse.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useCms } from '/~/composables/cms/use-cms'
import { useHowItWorks } from '/~/templates/bill-payments/modals/how-it-works/use-how-it-works'
import DrawerFaqSlider from './components/drawer-faq-slider.vue'

const {
  howItWorks: cmsHowItWorks,
  benefits: cmsBenefits,
  faqQuestions,
  ewalletLabels,
} = useCms()
const { howItWorks: defaultHowItWorks, benefits: defaultBenefits } =
  useHowItWorks()

const howItWorksItems = computed(() => {
  const items = cmsHowItWorks.value.items?.length
    ? cmsHowItWorks.value.items
    : defaultHowItWorks.value.items

  return items.map((item) => ({
    ...item,
    text: item.description,
    image: 'desktop' in item.image ? item.image.desktop : item.image,
  }))
})
const benefitsItems = computed(() => {
  const items = cmsBenefits.value.items?.length
    ? cmsBenefits.value.items
    : defaultBenefits.value.items

  return items.map((item) => ({
    ...item,
    text: item.description,
    image: 'desktop' in item.image ? item.image.desktop : item.image,
  }))
})
</script>

<template>
  <base-aside-page title="FAQ's">
    <div class="mb-2.5 font-bold text-eonx-neutral-600">
      {{ cmsHowItWorks.title ?? 'How it Works' }}
    </div>
    <div class="mb-[30px] rounded-lg bg-light p-6">
      <drawer-faq-slider :slides="howItWorksItems" />
    </div>
    <div class="mb-2.5 font-bold text-eonx-neutral-600">
      {{ cmsBenefits.title ?? `Benefits of ${ewalletLabels.ewallet}` }}
    </div>
    <div class="mb-[30px] rounded-lg bg-light p-6">
      <drawer-faq-slider :slides="benefitsItems" />
    </div>
    <template v-if="faqQuestions.length">
      <div class="mb-2.5 font-bold text-eonx-neutral-600">
        Frequently Asked Questions
      </div>
      <div class="mb-[30px] rounded-lg bg-light p-6">
        <div class="-my-6">
          <div
            v-for="(question, index) of faqQuestions"
            :key="index"
            class="-mx-6 px-6"
            :class="index < faqQuestions.length - 1 && 'border-b-2'"
          >
            <base-collapse :autoscroll="false">
              <template #icon="{ visible }">
                <base-icon
                  :svg="visible ? 'minus' : 'plus'"
                  color="primary"
                  size="md"
                  class="shrink-0"
                />
              </template>
              <template #trigger>
                <div class="mr-5 py-5 font-bold text-primary">
                  {{ question.title }}
                </div>
              </template>
              <div class="text-eonx-neutral-600" v-html="question.text" />
            </base-collapse>
          </div>
        </div>
      </div>
    </template>
  </base-aside-page>
</template>
